@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* i.e. 1rem */
}

body {
  font-family: Lato, "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit; /* stylelint-disable-line */
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  /* stylelint-disable-line */
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
  background-color: #edf3f6;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.Calendar {
  background-color: white;
  width: 43.23vw;
  height: 62vh;
  color: #5c6776;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BigNumber {
  background-color: #e45954;
  box-shadow: 2px 2px 40px 9px rgba(0, 0, 0, 0.2);
  width: 31.25vw;
  height: 77.78vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: calc(2.67vw + 2.67vh);
}

.number {
  font-size: calc(10.67vw + 10.67vh);
  margin-top: 6.5vh;
}

.calendarNumbers {
  width: calc(4.5vw * 7);
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.numberDiv {
  width: 4.5vw;
  height: 8.2vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1.33vh + 1.33vw);
}

.Calendar h1 {
  margin-bottom: 3vh;
  font-weight: 700;
  font-size: calc(2vh + 2vw);
  width: 100%;
  text-align: center;
}

.rows6 .Calendar {
  height: 70.2vh;
}

.rows6 .BigNumber {
  height: 85.98vh;
}

.smallNumber {
  opacity: 0.5;
}

.red {
  opacity: 1;
  width: 4.2vw;
  height: 4.2vw;
  max-width: 8.2vh;
  max-height: 8.2vh;
  background-color: #e45954;
  color: white;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tickerTape {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  height: 100vh;
  color: #5c6776;
}

.tickerTape sup {
  font-size: 1.8vw;
  margin-bottom: 0.8vw;
  margin-right: 0.5vw;
}

.tickerTape span {
  margin-right: 0.7vw;
}

.ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #5c6776;
  font-size: 2vh;
  width: 80vw;
  margin: auto;
  text-align: center;
}

@media screen and (orientation: portrait) {
  .App {
    flex-direction: column-reverse;
  }

  .BigNumber {
    width: 88vw;
    height: 33.33vh;
  }

  .rows6 .BigNumber {
    height: 33.33vh;
  }

  .Calendar {
    width: 77vw;
    height: 35vh;
  }

  .rows6 .Calendar {
    height: 35vh;
  }

  .smallNumber {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number {
    font-size: calc(10.67vw + 10.67vh);
    margin-top: 0;
  }

  .Calendar h1 {
    margin-bottom: 1vh;
  }

  .calendarNumbers {
    width: calc(7.8vw * 7);
  }

  .numberDiv {
    width: 7.8vw;
    height: 4.5vh;
    text-align: center;
    font-size: calc(1.33vh + 1.33vw);
  }

  .red {
    opacity: 1;
    width: 4.2vh;
    height: 4.2vh;
    max-width: 7.8vw;
    max-height: 7.8vw;
  }
}

@media (min-aspect-ratio: 11/3) {
  .BigNumber {
    height: 90vh;
  }

  .rows6 .BigNumber {
    height: 90vh;
  }
}

